<template>
	<div class="selection-menu" :style="`top: ${top}px; left: ${left}px;`">
		<div class="menu-colors" v-if="showDropdownColors" :style="`top: ${topMenuColors}px; max-height: ${maxHeightMenuColors};`">
			<div class="title-list">COLOR</div>
			<div class="option" @click.stop="convertTo('color', 'default')">
				<div class="example">A</div>
				<div class="text">Default</div>
			</div>
			<div class="option color-gray" @click.stop="convertTo('color', 'gray')">
				<div class="example">A</div>
				<div class="text">Gray</div>
			</div>
			<div class="option color-brown" @click.stop="convertTo('color', 'brown')">
				<div class="example">A</div>
				<div class="text">Brown</div>
			</div>
			<div class="option color-orange" @click.stop="convertTo('color', 'orange')">
				<div class="example">A</div>
				<div class="text">Orange</div>
			</div>
			<div class="option color-yellow" @click.stop="convertTo('color', 'yellow')">
				<div class="example">A</div>
				<div class="text">Yellow</div>
			</div>
			<div class="option color-green" @click.stop="convertTo('color', 'green')">
				<div class="example">A</div>
				<div class="text">Green</div>
			</div>
			<div class="option color-blue" @click.stop="convertTo('color', 'blue')">
				<div class="example">A</div>
				<div class="text">Blue</div>
			</div>
			<div class="option color-purple" @click.stop="convertTo('color', 'purple')">
				<div class="example">A</div>
				<div class="text">Purple</div>
			</div>
			<div class="option color-pink" @click.stop="convertTo('color', 'pink')">
				<div class="example">A</div>
				<div class="text">Pink</div>
			</div>
			<div class="option color-red" @click.stop="convertTo('color', 'red')">
				<div class="example">A</div>
				<div class="text">Red</div>
			</div>
			<div class="title-list">BACKGROUND</div>
			<div class="option" @click.stop="convertTo('bgcolor', 'default')">
				<div class="example">A</div>
				<div class="text">Default background</div>
			</div>
			<div class="option background-gray" @click.stop="convertTo('bgcolor', 'gray')">
				<div class="example">A</div>
				<div class="text">Gray background</div>
			</div>
			<div class="option background-brown" @click.stop="convertTo('bgcolor', 'brown')">
				<div class="example">A</div>
				<div class="text">Brown background</div>
			</div>
			<div class="option background-orange" @click.stop="convertTo('bgcolor', 'orange')">
				<div class="example">A</div>
				<div class="text">Orange background</div>
			</div>
			<div class="option background-yellow" @click.stop="convertTo('bgcolor', 'yellow')">
				<div class="example">A</div>
				<div class="text">Yellow background</div>
			</div>
			<div class="option background-green" @click.stop="convertTo('bgcolor', 'green')">
				<div class="example">A</div>
				<div class="text">Green background</div>
			</div>
			<div class="option background-blue" @click.stop="convertTo('bgcolor', 'blue')">
				<div class="example">A</div>
				<div class="text">Blue background</div>
			</div>
			<div class="option background-purple" @click.stop="convertTo('bgcolor', 'purple')">
				<div class="example">A</div>
				<div class="text">Purple background</div>
			</div>
			<div class="option background-pink" @click.stop="convertTo('bgcolor', 'pink')">
				<div class="example">A</div>
				<div class="text">Pink background</div>
			</div>
			<div class="option background-red" @click.stop="convertTo('bgcolor', 'red')">
				<div class="example">A</div>
				<div class="text">Red background</div>
			</div>
		</div>

		<div class="section first-group">
			<button class="link" @click.stop="convertTo('link')">
				<div class="text"><arrow-long />Link</div>
			</button>
			<button v-if="showUnLinkOption" @click.stop="convertTo('unlink')"><div class="text">Unlink</div></button>
		</div>

		<div class="section">
			<button @click.stop="convertTo('bold')" class="bold"><div class="text">B</div></button>
			<button @click.stop="convertTo('italic')" class="italic"><div class="text">i</div></button>
			<button @click.stop="convertTo('underline')" class="underline"><div class="text">U</div></button>
			<button @click.stop="convertTo('strikeThrough')" class="strike-through"><div class="text">S</div></button>
		</div>

		<div class="section last-group">
			<button @click.stop="openMenuTextColors()">
				<div class="text">A</div>
			</button>
		</div>
	</div>
</template>

<script>
import ArrowLong from "@/components/Icons/ArrowLong";

export default {
	components: {
		ArrowLong,
	},
	props: {
		position: Object,
		blockId: Number,
		rangeSelected: Range,
	},
	watch: {
		position(newValue) {
			this.top = Math.floor(newValue.y) - 35;
			this.left = Math.floor(newValue.x) - 15;
		},
	},
	data() {
		return {
			top: 0,
			left: 0,
			topMenuColors: 35,
			maxHeightMenuColors: "initial",
			showUnLinkOption: false,
			showDropdownColors: false,
		};
	},
	mounted() {
		this.top = Math.floor(this.position.y) - 35;
		this.left = Math.floor(this.position.x) - 15;

		const selection = window.getSelection();
		let parentAnchor = false;
		let childAnchor = false;

		// Check if any parent element is an anchor element
		let parentNode = selection.anchorNode.parentNode;
		while (parentNode) {
			if (parentNode.tagName === "A") {
				parentAnchor = true;
				break;
			}
			parentNode = parentNode.parentNode;
		}

		// Check if any child element is an anchor element
		const range = selection.getRangeAt(0);
		const nodeIterator = document.createNodeIterator(range.commonAncestorContainer, NodeFilter.SHOW_ELEMENT);
		let node;
		while ((node = nodeIterator.nextNode())) {
			if (node.tagName === "A") {
				childAnchor = true;
				break;
			}
		}

		if (parentAnchor || childAnchor) {
			this.showUnLinkOption = true;
		} else {
			this.showUnLinkOption = false;
		}
	},
	methods: {
		convertTo(tag, colorName = "") {
			var selectedText = window.getSelection().toString();

			// Check if any text is currently selected
			if (selectedText.length === 0) {
				// var highlightSpan = document.querySelector(".highlight");

				//  // If no text is selected, create a new selection range containing the text
				// var range = document.createRange();
				// range.selectNodeContents(highlightSpan);

				var selection = window.getSelection();
				selection.removeAllRanges();
				selection.addRange(this.rangeSelected);
			}

			if (tag == "bold") {
				this.makeBold();
			} else if (tag == "italic") {
				this.makeItalic();
			} else if (tag == "underline") {
				this.makeUnderline();
			} else if (tag == "strikeThrough") {
				this.makeStrikeThrough();
			} else if (tag == "link") {
				this.makeLink();
			} else if (tag == "unlink") {
				document.execCommand("unlink", false, null);
			} else if (tag == "color") {
				this.changeColor({ colorName: colorName });
			} else if (tag == "bgcolor") {
				this.changeColor({ bgColorName: colorName });
			}
			this.$root.$emit("close_action_menu");
		},
		makeBold() {
			document.execCommand("bold", false, null);
		},
		makeItalic() {
			document.execCommand("italic", false, null);
		},
		makeUnderline() {
			document.execCommand("underline", false, null);
		},
		makeStrikeThrough() {
			document.execCommand("strikeThrough", false, null);
		},
		makeLink() {
			this.$root.$emit("open_modal", "create_link_on_project", {
				selectionRange: document.getSelection().getRangeAt(0),
				blockId: this.blockId,
			});
		},
		openMenuTextColors() {
			// Get the dropdown element
			const dropdown = document.getElementById(`row-id-${this.blockId}`);

			// Get the distance between the top of the dropdown and the top of the document
			const dropdownTop = dropdown.getBoundingClientRect().top;

			// Get the height of the dropdown
			const dropdownHeight = 650;

			// Get the height of the viewport
			const windowHeight = window.innerHeight;

			// Calculate the available space below the dropdown
			const spaceBelow = windowHeight - (dropdownTop + 10);

			// Check if there is enough space below the dropdown to open it downwards
			if (spaceBelow >= dropdownHeight) {
				// Open the dropdown downwards
				this.topMenuColors = 35;
				this.maxHeightMenuColors = "initial";
			} else if (windowHeight - 20 >= dropdownHeight) {
				// Calculate the distance to move the dropdown upwards
				// dropdownHeight - spaceBelow - 35 (the actual top value) + 10 (to add a margin bottom)
				const distanceToMoveUp = dropdownHeight - spaceBelow - 35 + 10;

				// negative top value, to move upwards
				this.topMenuColors = -distanceToMoveUp;
				this.maxHeightMenuColors = "initial";
			} else {
				var newMaxHeight = windowHeight - 20;
				this.maxHeightMenuColors = `${windowHeight - 20}px`;
				this.topMenuColors = -(newMaxHeight - spaceBelow - 35 + 10);
			}

			this.showDropdownColors = true;

			document.querySelector("body .main-template .container-tbf").style.overflow = "hidden";
		},
		changeColor(options) {
			var selection = window.getSelection();

			if (selection.rangeCount > 0) {
				var range = selection.getRangeAt(0);
				var parentElement = range.commonAncestorContainer.parentNode;
				if (parentElement.classList.contains("font-color") || parentElement.classList.contains("bg-color")) {
					var isFullSelection = range.startOffset == 0 && range.endOffset == parentElement.childNodes[0].length;
					if (isFullSelection) {
						parentElement.classList = this.generateSpanClass(parentElement.classList, options);
					} else {
						var existingSpan = parentElement;
						var rangeStartContainer = range.startContainer;
						var rangeEndContainer = range.endContainer;
						var rangeStartOffset = range.startOffset;
						var rangeEndOffset = range.endOffset;
						var textBeforeSelection = existingSpan.childNodes[0].nodeValue.substring(0, rangeStartOffset);
						var textInsideSelection = existingSpan.childNodes[0].nodeValue.substring(rangeStartOffset, rangeEndOffset);
						var textAfterSelection = existingSpan.childNodes[0].nodeValue.substring(rangeEndOffset);
						existingSpan.innerHTML = textBeforeSelection;
						var beforeSpan = document.createElement("span");
						beforeSpan.className = existingSpan.className;
						beforeSpan.classList.add("font-color");
						beforeSpan.textContent = textBeforeSelection;
						existingSpan.before(beforeSpan);
						var newSpan = document.createElement("span");
						newSpan.className = this.generateSpanClass(parentElement.classList, options);
						newSpan.classList.add("font-color");
						newSpan.textContent = textInsideSelection;
						existingSpan.before(newSpan);
						var afterSpan = document.createElement("span");
						afterSpan.className = existingSpan.className;
						afterSpan.classList.add("font-color");
						afterSpan.textContent = textAfterSelection;
						existingSpan.before(afterSpan);
						existingSpan.remove();
						// Remove any nested span elements with the same class name
						var nestedSpans = newSpan.querySelectorAll(".font-color");
						nestedSpans.forEach((nestedSpan) => {
							var textNode = document.createTextNode(nestedSpan.textContent);
							nestedSpan.parentNode.replaceChild(textNode, nestedSpan);
						});
					}
					return;
				}

				var fragment = range.extractContents();
				var existingSpans = fragment.querySelectorAll(".font-color, .bg-color");
				existingSpans.forEach((existingSpan) => {
					if (options.bgColorName) {
						existingSpan.classList.add("bg-color-" + options.bgColorName);
					}
				});
				var span = document.createElement("span");
				span.className = this.generateSpanClass(span.classList, options);
				span.appendChild(fragment);
				range.insertNode(span);
				// Remove any nested bg-color elements with the same class name
				var nestedSpans = span.querySelectorAll(".bg-color");
				nestedSpans.forEach((nestedSpan) => {
					if (!nestedSpan.classList.contains("font-color")) {
						var textNode = document.createTextNode(nestedSpan.textContent);
						nestedSpan.parentNode.replaceChild(textNode, nestedSpan);
					}
				});
			}
		},
		generateSpanClass(existingClassList, options) {
			let spanClass = "";

			// Add existing font-color-* and bg-color-* classes to spanClass
			existingClassList.forEach((className) => {
				if (className.startsWith("font-color-") || className.startsWith("bg-color-")) {
					spanClass += className + " ";
				}
			});

			if (options.colorName) {
				spanClass += "font-color font-color-" + options.colorName;
			}
			if (options.bgColorName) {
				spanClass += " bg-color bg-color-" + options.bgColorName;
			}
			return spanClass.trim();
		},
	},
};
</script>
